@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.greedy-nav, .masthead, .page__footer {
  background: none;
}

body {
  background-color: #f9f9f9;
}

@media (min-width: 80em) {
  .masthead__inner-wrap {
    max-width: 1280px;
  }
}

.masthead {
  border-bottom: 1px solid #b5b5b5;
}

h1 {
  font-size: 1.463em;
}

h4 {
  font-size: 0.97em;
}

.page__content p, .page__content li, .page__content dl {
  font-size: 0.75em;
}

@media (max-width: 64em) {
  .page__content p, .page__content li, .page__content dl {
    font-size: 1em;
  }
}

.site-subtitle {
  margin-right: 50px;
}

.author__avatar img {
  padding: 0;
  border: none;
  border-radius: 0;
}

.author__actions {
  display: table-cell;
  vertical-align: middle;
  padding-left: 0.5rem;
}

@media (min-width: 64em) {
  .author__actions {
    padding-top: 1.5em;
    display: block;
    padding-left: 0;
  }
}

.feature_item .feature_img, .feature_item .feature_description {
  display: block;
}

@media (min-width: 64em) {
  .feature_item .feature_img, .feature_item .feature_description {
    display: table-cell;
  }

  .feature_item div:last-child {
    padding-left: 10px;
  }

  .feature_item .feature_img {
    width: 50%;
  }

  .feature_item .feature_description {
    vertical-align: top;
    width: 49%;
  }

  .feature_item .feature_description {
    padding: 1em;
  }

  .feature_item .feature_description p {
    font-size: 0.75em;
  }
}

.feature_item .feature_description h6 {
  margin-top: 0;
}

.btn--info {
  background-color: #3da0e3;
  color: #fff;
  width: 115px;
}

.btn--info:visited {
  background-color: #1e88cf;
  color: #fff;
}

.btn--secondary {
  background-color: #b5b5b5;
  color: #fff;
  width: 115px;
}

.btn--secondary:visited {
  background-color: #aaa;
  color: #fff;
}

.btn--secondary:hover {
  color: #fff;
}

@media (max-width: 64em) {
  .btn--secondary {
    display: none;
  }
}

.shadow {
  -webkit-box-shadow: 0px 8px 8px 8px #aaa;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    0px 8px 8px 8px #aaa;  /* Firefox 3.5 - 3.6 */
  box-shadow:         0px 8px 8px 8px #aaa;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  margin: 10px 0px 40px 0px;
}

.social-link {
  text-decoration: none;
}

.rounded {
  object-fit: cover;
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.cc-screen {
  border: 10px solid #121212;
  border-radius: 10px;
}

.social-icons {
  .fa-telegram {
    color: #0088cc;
  }
}

.verify-box {
  width: 650px;
  height: 160px;
  background-color: #3B3E42; /* Grey color */
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5); /* Drop shadow */
  margin: 50px auto; /* Center the box horizontally */
}

.verify-box table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 0 6px;
  padding: 20px;
}

.verify-box td {
  border: 0;
  padding: 11px;
  font-size: 13px;
  color: #D3D3D3;
  font-family: system-ui;
}

.verify-box .verify-link {
  color: #229DF5;
}

.verify-box .check-circle {
  margin: 0 8px 2px 0;
}